


























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PlayoffLineTO, PlayoffSerieTO} from '@/model/ranking.model';
import {Team} from '@/model/core/core-data.model';

@Component({
  components: {},
})
export default class PlayoffSerie extends Vue {
  @Prop(Object) playoffSerie!: PlayoffSerieTO;
  @Prop(Boolean) header = false;

  readonly NR_OF_DISPLAYED_GAMES = 7;

  get lines(): PlayoffLineTO[] {
    return this.playoffSerie ? [
      this.line(this.playoffSerie.team1, this.playoffSerie.standing.home, this.playoffSerie.currentStanding.home, this.playoffSerie),
      this.line(this.playoffSerie.team2, this.playoffSerie.standing.away, this.playoffSerie.currentStanding.away, this.playoffSerie)
    ] : [];
  }

  scoreStyle(score: any) {
    let classes = ['hf-po-score'];
    if (score && !score.past) {
      classes = [...classes, 'hf-text-muted'];
    }
    return classes;
  }

  get headers() {
    const columns = [
      {label: ''},
      {label: ''},
    ];
    const scores = Array.apply(null, Array(this.NR_OF_DISPLAYED_GAMES)).map((x, i) => {
      return {
        label: this.header ? `${i + 1}` : '',
      };
    });
    const headers = [...columns, ...scores, ''];
    return headers;
  }

  private line(team: Team, wins: number, currentWins: number, playoffSerie: PlayoffSerieTO): any {
    if (!this.playoffSerie) {
      return undefined;
    }
    const scores = this.scores(this.playoffSerie.games.map(g => {
      const past = this.playoffSerie.pastGameIds.filter(gameId => gameId === g.gameId).length > 0;
      const goals = team.teamId === g.homeTeamId ? g.homeGoals : g.awayGoals;
      return {past, goals};
    }));
    return {
      wins: wins,
      currentWins: currentWins,
      teamId: playoffSerie.winnerId,
      team: playoffSerie.winnerId === team.teamId ? team.shortName.toUpperCase() : team.shortName,
      scores: scores,
    };
  }

  scores(results: any[]): any {
    let scores: any[] = Array.apply(null, Array(this.NR_OF_DISPLAYED_GAMES)).map((x, i) => {
      return {idx: i};
    });
    scores = results.concat(scores).slice(0, this.NR_OF_DISPLAYED_GAMES);
    return scores;
  }

}
