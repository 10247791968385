

























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {LeagueId} from '@/model/core/core-data.model';
import {
  ALL_GROUP_ID,
  EMPTY_GROUP_ID,
  FOCUS_DATE,
  SELECTED_GROUP_ID,
  SELECTED_LEAGUE_ID,
  SITE_CONFIG
} from '@/store/context.store';
import {PlayoffSerieTO, Ranking, RankingGroup, RankingId} from '@/model/ranking.model';
import {GROUP_IDS, PLAYOFF, RANKING} from '@/store/data/getters';
import {Getter} from 'vuex-class';
import {LOAD_RANKING} from '@/store/data/actions';
import {SiteConfig} from '@/model/site/context.model';
import Loading from 'vue-loading-overlay';
import PlayoffSerie from '@/components/event/PlayoffSerie.vue';
import ContextGroupSelection from '@/components/ui/ContextGroupSelection.vue';
import StringFilters from '@/mixins/string-filters.mixin';
import PanelBox from '@/components/ui/PanelBox.vue';
import dayjs from 'dayjs';
import {RANKING_DATE_FORMAT} from '@/utils/date.util';

@Component({
  mixins: [StringFilters],
  components: {PanelBox, ContextGroupSelection, PlayoffSerie, Loading},
})
export default class EventRanking extends Vue {

  @Prop(Date) date!: Date;
  @Prop(String) seasonId?: string;
  @Prop(String) gameTypeId?: string;
  @Prop(Boolean) force?: string;

  @Getter(FOCUS_DATE) focusDate!: Date;
  @Getter(SELECTED_LEAGUE_ID) selectedLeagueId!: LeagueId;
  @Getter(SELECTED_GROUP_ID) selectedGroupId?: string;
  @Getter(GROUP_IDS) groupIds?: string[];
  @Getter(RANKING) ranking!: Ranking;
  @Getter(PLAYOFF) playoffSeries!: PlayoffSerieTO[];
  @Getter(SITE_CONFIG) siteConfig!: SiteConfig;

  rankingEnabled = false;
  test = true;
  width: number = 0;

  get isReady(): boolean {
    if (!this.selectedLeagueId) {
      return false;
    }
    return true;
  }

  get title() {
    if (!this.date) {
      return 'Tabelle';
    }
    const date = dayjs(this.date).format(RANKING_DATE_FORMAT);
    return `Tabelle - ${date}`;
  }

  get columns() {
    const cols = [
      {field: 'rank', label: '#', numeric: true},
      {field: 'team', label: 'Team'},
      {field: 'games', label: 'Sp', numeric: true},
      {field: 'goals', label: 'Tore', centered: true},
      {field: 'pointsLost', label: 'VP', numeric: true},
      {field: 'points', label: 'P', numeric: true},
    ];
    if (this.test && this.width === 768) {
      Vue.$log.info('duplicating columns');
      cols.forEach((col: any) => {
        const newCol = JSON.parse(JSON.stringify(col));
        newCol.field += '2';
        cols.push(newCol);
      });
    }
    return cols;
  }

  get rankings(): RankingGroup[] {
    let rankings: RankingGroup[];
    if (this.hasGroups && this.ranking.groups) {
      rankings = this.ranking.groups.filter((group: RankingGroup) => {
        return this.selectedGroupId === ALL_GROUP_ID
          || this.selectedGroupId === EMPTY_GROUP_ID
          || this.selectedGroupId === group.group;
      });
    } else {
      rankings = [{group: 'any', entries: this.ranking.entries}];
    }
    return rankings;
  }

  get hasGroups() {
    return this.ranking && this.ranking.groups && this.ranking.groups.length > 0;
  }

  get showTitle() {
    if (!this.hasGroups) {
      return false;
    }
    return this.selectedGroupId === ALL_GROUP_ID || this.selectedGroupId === EMPTY_GROUP_ID;
  }

  get rankingId(): RankingId {
    const rankingId = new RankingId(this.selectedLeagueId, this.focusDate, this.seasonId, this.gameTypeId);
    this.$log.debug('rankingId', rankingId.key);
    return rankingId;
  }

  @Watch('rankingId')
  onRankingIdChanged(newRankingId: RankingId, oldRankingId: RankingId) {
    if (newRankingId.key !== oldRankingId.key) {
      this.$log.debug(`rankingId changed from ${oldRankingId.key} to ${newRankingId.key}`);
      this.loadRanking(newRankingId);
    }
  }

  get isShowEmptyMessage() {
    if (!this.showRanking) {
      return false;
    }
    const hasEntries = this.ranking && this.ranking.entries && this.ranking.entries.length > 0;
    const hasGroups = this.ranking && this.ranking.groups && this.ranking.groups.length > 0;
    return !(hasEntries || hasGroups);
  }

  get showRanking(): boolean {
    return !this.showRankingToggle || (this.showRankingToggle && this.rankingEnabled);
  }

  get showRankingToggle(): boolean {
    return this.playoffSeries !== undefined && this.playoffSeries.length > 0;
  }

  created() {
    if (this.rankingId.isValid()) {
      this.loadRanking(this.rankingId);
    }
  }

  loadRanking(rankingId: RankingId) {
    this.$store.dispatch(LOAD_RANKING, rankingId);
  }

}
