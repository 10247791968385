import Vue from 'vue';
import Component from 'vue-class-component';
import dayjs from 'dayjs';

@Component({
	filters: {
		date: function (date: Date, format: string) {
			if (date) {
				return dayjs(date).format(format);
			}
			return '';
		}
	},
})
export default class DateFilters extends Vue {

}

