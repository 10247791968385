import {LeagueId, Team} from '@/model/core/core-data.model';
import dayjs from 'dayjs';
import {GameInfo, GameResultTO} from '@/model/game/game.model';
import {DATE_FORMAT_ISO} from '@/utils/date.util';
import {Vue} from 'vue-property-decorator';

export class RankingId {

	readonly key: string;

	constructor(public readonly leagueId: LeagueId,
							public readonly date?: Date,
							public readonly seasonId?: string,
							public readonly gameTypeId?: string,
	) {
		if (!this.leagueId) {
			Vue.$log.warn('No leagueId set when creating RankingId');
		}
		this.key = `${this.leagueId}`;
		if (this.date) {
			const dateStr = dayjs(this.date).format(DATE_FORMAT_ISO);
			this.key += `_${dateStr}`;
		}
		if (this.seasonId) {
			this.key += `_${this.seasonId}`;
		}
		if (this.gameTypeId) {
			this.key += `_${this.gameTypeId}`;
		}
	}

	isValid(): boolean {
		return this.leagueId !== undefined && this.leagueId !== null && this.leagueId !== '';
	}
}

export interface Ranking {
	entries?: RankingEntry[];
	groups?: RankingGroup[];
}

export interface RankingGroup {
	group: string;
	entries?: RankingEntry[];
}

export interface RankingEntry {
	rank: number;
	team: string;
	games: number;
	goals: string;
	difference: string;
	pointsLost: number;
	points: number;
}

export class PlayoffSerieId {
	constructor (
		public seasonId: string,
		public leagueId: string,
		public round: string
	) {
	}

	get key(): string {
		return `${this.seasonId}-${this.leagueId}-${this.round}`;
	}
}

export class PlayoffLineTO {
	constructor (
		public wins: number,
		public team: any,
		public scores: any
	) {
	}
}

export class PlayoffSerieTO {
	constructor(
		public id: string,
		public team1: Team,
		public team2: Team,
		public winnerId: string,
		public standing: GameResultTO,
		public currentStanding: GameResultTO,
		public games: GameInfo[],
		public pastGameIds: string[],
		public firstGame: GameInfo
	) {
	}
}
