





















import {Component, Vue, Watch} from 'vue-property-decorator';
import {SELECT_GROUP_ID, SELECTED_GROUP_ID, SELECTED_LEAGUE_ID} from '@/store/context.store';
import {Getter} from 'vuex-class';
import {GROUP_IDS} from '@/store/data/getters';
import {IndexedGroups} from '@/model/game/game.model';
import { GROUP_RELEGATION } from '@/model/core/core-data.model';

@Component({
  components: {},
})
export default class ContextGroupSelection extends Vue {

  @Getter(SELECTED_GROUP_ID) selectedGroupId!: string;
  @Getter(SELECTED_LEAGUE_ID) selectedLeagueId!: string;
  @Getter(GROUP_IDS) groupIds!: IndexedGroups;

  get isVisible() {
    const limit = 0;
    return this.groupIdsForLeagueId && this.groupIdsForLeagueId.length > limit;
  }

  group(groupId: string) {
    if (this.isVisible && this.groupIds && this.groupIdsForLeagueId.length === 1) {
      if (groupId === GROUP_RELEGATION) {
        return 'Relegation';
      }
      return `Gruppe ${groupId}`;
    }
    return groupId.toUpperCase();
  }

  selectGroupId(groupId: string) {
    this.$store.commit(SELECT_GROUP_ID, groupId);
  }

  isActive(groupId: string) {
    return this.selectedGroupId === groupId;
  }

  get groupIdsForLeagueId() {
    return this.groupIds[this.selectedLeagueId];
  }

  @Watch('groupIdsForLeagueId', {immediate: true})
  onGroupIdsChange(newGroupIds: string[]) {
    if (!newGroupIds) {
      return;
    }
    const groupIds = newGroupIds.filter((groupId: string) => this.selectedGroupId === groupId);
    if (groupIds.length === 0) {
      const groupId = (this.groupIdsForLeagueId.length > 0 ? this.groupIdsForLeagueId[0] : undefined);
      if (!groupId) {
        return;
      }
      this.selectGroupId(groupId);
    }
  }

}
