
















import {Component, Prop, Vue} from 'vue-property-decorator';
import {GameInfo} from '@/model/game/game.model';
import {GAME_CONTENTS, GameContentType, Link} from '@/model/site/components.model';

@Component({
  components: {},
})
export default class GameLinks extends Vue {

  @Prop(Object) game!: GameInfo;
  @Prop() props?: any;

  links(game: GameInfo): Link[] {
    if (!game && this.props) {
      // for usage in BulmaTable
      game = this.props.data;
    }
    if (!game) {
      return [];
    }
    return [
      this.createLink(game, GameContentType.BERICHT, 'receipt', game.hasArticle),
      this.createLink(game, GameContentType.TELEGRAMM, 'table', game.hasData),
      this.createLink(game, GameContentType.BILDER, 'images', game.hasPictures),
    ];
  }

  private createLink(game: GameInfo, type: GameContentType, iconName: string, enabled: boolean): Link {
    return {
      target: `/spiel/${type}/${game.gameId}`,
      iconName: iconName,
      iconPack: 'fas',
      enabled: enabled,
      tooltip: GAME_CONTENTS[type] ? GAME_CONTENTS[type].toString() : '',
    };
  }

}
